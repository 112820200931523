@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Dosis&display=swap');

body {
  margin: 0;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4 {
  letter-spacing: -1px;
  font-weight: 700;
}

p {
  letter-spacing: -1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand-primary-color {
  color: #638052;
}

.brand-primary-bg {
  background-color: #638052;
}

.brand-secondary-bg {
  background-color: #d8dcb9;
}

pre {
  max-width: 100%;
  width: 100vw;
}